<template>
    <List :items="bookingsFiltered" :scroll="true" :search="false" :headers="headers" :getrowclass="getLinkColor" @click="clickRow" style="font-size: 13px;height: 440px;">
        <template slot-scope="statement">
            <td>{{ statement.bookingDate }}</td>
            <td>{{ statement.serie }}</td>
            <td>{{ statement.no }}</td>
            <td>{{ statement.text }}</td>
            <td>{{ statement.count }}</td>
            <td align="right">{{ statement.amount | swedishNumberFormat }}</td>
            <td align="right">{{ statement.balance | swedishNumberFormat }}</td>
        </template>
    </List>
</template>

<script>
import List from "../../../../components/list.vue";
export default {
    components: {
        List,
    },
    props: ["bookings", "selectedList", "coloredList", "showChecked", "viewOption"],
    data: function() {
        return {
            headers: [
                { name: "Datum", prop: "d", width: "90px" },
                { name: "Serie", prop: "serie", width: "60px" },
                { name: "Nr", prop: "no", width: "60px" },
                { name: "Text", prop: "text" },
                { name: "Antal", prop: "count", width: "70px" },
                { name: "Belopp", prop: "amount", width: "90px", align: "right" },
                { name: "Saldo", prop: "balance", width: "120px", align: "right" },
            ],
        };
    },
    methods: {
        getLinkColor: function(transaction) {
            let diff = false;
            let ok = false;
            let select = false;

            let transactionId = transaction.id;
            select = this.selectedList.some(item => item == transactionId);

            let marked = this.coloredList.filter(function(item) {
                return item.id == transactionId;
            });
            if (marked.length > 0) {
                if (marked[0].match) {
                    ok = true;
                } else {
                    diff = true;
                }
            }
            return {
                selected: select,
                linkcolorok: ok,
                linkcolordiff: diff,
            };
        },
        clickRow: function(booking) {
            this.$emit("on-select", booking.id);
        },
    },
    computed: {
        bookingsMaped: function() {
            return this.bookings.map(item => ({
                id: item.id,
                amount: item.amount / 100,
                balance: item.balance / 100,
                text: item.text,
                bookingDate: item.d,
                serie: item.serie,
                no: item.no,
                count: item.count,
            }));
        },
        bookingsFiltered: function() {
            return this.bookingsMaped.filter(item => {
                return (
                    (this.showChecked || (!this.getLinkColor(item).linkcolorok && !this.getLinkColor(item).linkcolordiff)) &&
                    ((this.viewOption == "in" && item.amount >= 0) || (this.viewOption == "out" && item.amount < 0) || this.viewOption == "all")
                );
            });
        },
    },
};
</script>

<style>
.linkcolorok {
    background-color: #ccddcc !important;
}
.linkcolordiff {
    background-color: #f7d3ab !important;
}
.selected {
    border-style: solid !important;
    border-width: 2px !important;
    border-color: #000000 !important;
}
/* .clickable-row {
  cursor: pointer;
} */
/* th {
  background: white;
  position: sticky;
  top: 0;
} */
</style>
