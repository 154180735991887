<template>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-half">
                    <div class="column field is-grouped is-full">
                        <ClientsSearch @on-selected="onClientChange" class="mr-3" />
                        <DropDown :options="financialYears.list" request-text="Välj räkenskapsår ..." @on-change="onFinancialYearChange" state="ready" :value="financialYears.selected" />
                        <DropDown :options="months" request-text="Välj månad ..." @on-change="onMonthChange" state="ready" :value="selectedMonth" />
                    </div>
                    <div class="column is-half">
                        <dl>
                            <dt>Bank</dt>
                            <dd>{{ financialYears.bank }}</dd>
                            <dt>Kontonummer</dt>
                            <dd>{{ financialYears.accountNumber }}</dd>
                        </dl>
                    </div>
                    <div class="column is-full">
                        <p class="buttons">
                            <button :disabled="state == 'loading'" class="button is-primary" @click.prevent="onAutoConciliation">
                                <span class="icon">
                                    <i class="fa fa-robot" />
                                </span>
                                <span>Autoavstäm</span>
                            </button>
                            <button class="button is-success" @click.prevent="onRefresh">
                                <span class="icon">
                                    <i class="fa fa-sync" />
                                </span>
                                <span>Uppdatera</span>
                            </button>
                            <!-- <button class="button is-success" @click.prevent="onCopyToNew">
                <span class="icon">
                  <i class="far fa-copy"></i>
                </span>
                <span>Kopiera till ny</span>
              </button> -->
                        </p>
                    </div>
                    <div class="column is-full" style="display: flex;">
                        <CheckBox v-model="showChecked" label="Visa matchade" />
                        <RadioButtonList :entries="viewOptions" label="Visa matchade" :horizontal="true" @change="onChangeViewOption" style="margin-left:10px;" />
                    </div>
                </div>
                <div class="column is-half">
                    <MatchBox
                        :statements="markedStatements"
                        :bookings="markedBookings"
                        :new-match="newMatch"
                        :receipt="receipt"
                        :earning="earning"
                        :re-conciliation="selectedRecon"
                        @on-abort-match="onAbortMatch"
                        @on-save-match="onSaveMatch"
                        @on-remove-match="onRemoveMatch"
                        @on-create-receipts="onCreateReceipts"
                        @on-create-earnings="onCreateEarnings"
                        @on-remove-receipt="onRemoveReceipt"
                        @on-remove-earning="onRemoveEarning"
                        @on-remove-transaction="onRemoveTransaction"
                    />
                </div>
            </div>
            <div class="columns">
                <div class="column is-half">
                    <div class="flex justify-between px-2 py-4">
                        <el-tooltip class="item" effect="dark" content="Matchad" placement="top">
                            <div class="flex items-center px-2 border-2 border-white rounded-full">
                                <span class="flex h-4 w-4 mr-1 rounded-full bg-red-500" style="background-color: #ccddcc;" />
                                <p>
                                    Matchad
                                </p>
                            </div>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="Open Banking kvitto matchning" placement="top">
                            <div class="flex items-center px-2 border-2 border-white rounded-full">
                                <span class="flex h-4 w-4 mr-1 rounded-full bg-red-500" style="background-color: #ebe981;" />
                                <p>
                                    Open Banking kvitto matchning
                                </p>
                            </div>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="Kvittoförfrågan" placement="top">
                            <div class="flex items-center px-2 border-2 border-white rounded-full">
                                <span class="flex h-4 w-4 mr-1 rounded-full" style="background-color: #abccf7;" />
                                <p>
                                    Kvittoförfrågan
                                </p>
                            </div>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="Intäktsförfrågan" placement="top">
                            <div class="flex items-center px-2 border-2 border-white rounded-full">
                                <span class="flex h-4 w-4 mr-1 rounded-full bg-red-500" style="background-color: #e7cce9;" />
                                <p>
                                    Intäktsförfrågan
                                </p>
                            </div>
                        </el-tooltip>
                    </div>

                    <BankStatements
                        :statements="mappedStatements"
                        :selected-list="selectedBank"
                        :colored-list="coloredBankList"
                        :show-checked="showChecked"
                        :view-option="viewOption"
                        @on-select="onSelectStatement"
                    />
                </div>
                <div class="column is-half">
                    <BookingList
                        :bookings="bookings"
                        :selected-list="selectedBook"
                        :colored-list="coloredBookList"
                        :show-checked="showChecked"
                        :view-option="viewOption"
                        @on-select="onSelectBooking"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import DropDown from "../../../../components/dropdown.vue";
import CheckBox from "../../../../components/checkbox.vue";
import RadioButtonList from "../../../../components/radioButtonList.vue";
import BankStatements from "./bankStatements.vue";
import BookingList from "./bookingList.vue";
import MatchBox from "./matchBox.vue";
import Api from "../conciliation.api.js";
import ClientsSearch from "../../../../components/clientsSearch.vue";

export default {
    props: ["statements", "bookings", "conciliations", "conciliationBanks", "conciliationBookings", "financialYears", "state"],
    components: {
        BankStatements,
        BookingList,
        MatchBox,
        DropDown,
        CheckBox,
        ClientsSearch,
        RadioButtonList,
    },
    data: function() {
        return {
            selectedBank: [],
            selectedBook: [],
            selectedRecon: {},
            coloredBankList: [],
            coloredBookList: [],
            showChecked: true,
            newMatch: false,
            coloredMarked: false,
            lastReconId: 0,
            receipt: false,
            earning: false,
            viewOptions: [
                { name: "Alla", value: "all" },
                { name: "Intäkter", value: "in" },
                { name: "Kostnader", value: "out" },
            ],
            viewOption: "all",
            months: [],
            selectedClient: "",
            selectedMonth: "",
            selectedFinancialYear: undefined,
        };
    },
    watch: {
        conciliations: function() {
            this.createColoredBankList();
            this.createColoredBookList();
        },
        financialYears: async function() {
            this.selectedFinancialYear = this.financialYears.selected;
            this.selectedMonth = "";
            this.months = [];
            this.months = await Api.getMonths(this.selectedClient, this.financialYears.selected);
        },
    },
    methods: {
        onTabSelected: function(e) {
            alert(e);
        },
        onChangeViewOption: function(item) {
            this.viewOption = item.value;
        },
        onAbortMatch: function() {
            this.selectedBank = [];
            this.selectedBook = [];
            this.selectedRecon = {};
            this.receipt = false;
            this.earning = false;
            this.newMatch = false;
            this.coloredMarked = false;
        },
        onSaveMatch: function(data) {
            this.selectedBank = [];
            this.selectedBook = [];
            this.selectedRecon = {};
            this.newMatch = false;
            this.$emit("on-save-match", data);
        },
        onRemoveMatch: function(recId) {
            this.selectedBank = [];
            this.selectedBook = [];
            this.selectedRecon = {};
            this.newMatch = false;
            this.$emit("on-remove-match", recId);
        },
        onCreateReceipts: function(data) {
            this.selectedBank = [];
            this.selectedBook = [];
            this.newMatch = false;
            this.$emit("on-create-receipts", data);
        },
        onCreateEarnings: function(data) {
            this.selectedBank = [];
            this.selectedBook = [];
            this.newMatch = false;
            this.$emit("on-create-earnings", data);
        },
        onRemoveReceipt: function(data) {
            this.selectedBank = [];
            this.selectedBook = [];
            this.newMatch = false;
            this.$emit("on-remove-receipt", data);
        },
        onRemoveEarning: function(data) {
            this.selectedBank = [];
            this.selectedBook = [];
            this.newMatch = false;
            this.$emit("on-remove-earning", data);
        },
        onRemoveTransaction: function(data) {
            this.selectedBank = [];
            this.selectedBook = [];
            this.newMatch = false;
            this.$emit("on-remove-transaction", data);
        },
        onShowMatched: function(value) {
            this.$emit("on-show-matched", value);
        },
        onSelectBooking: function(id) {
            const recon = this.conciliationBookings.find(item => item.id == id);
            if (recon != undefined) {
                if (!this.coloredMarked || this.lastReconId != recon.recId) {
                    this.selectedRecon = this.conciliations.find(item => item.recId == recon.recId);
                    this.selectedBook = this.conciliationBookings.filter(item => item.recId == recon.recId).map(unit => unit.id);
                    this.selectedBank = this.conciliationBanks.filter(item => item.recId == recon.recId).map(unit => unit.id);
                    this.coloredMarked = true;
                    this.lastReconId = recon.recId;
                } else {
                    this.selectedRecon = {};
                    this.selectedBook = [];
                    this.selectedBank = [];
                    this.coloredMarked = false;
                }
                this.newMatch = false;
            } else {
                this.coloredMarked = false;
                this.selectedRecon = {};
                if (!this.newMatch && !this.receipt) {
                    this.selectedBank = [];
                    this.selectedBook = [];
                    this.newMatch = true;
                }
                if (this.selectedBook.find(value => value == id)) {
                    const index = this.selectedBook.indexOf(id);
                    this.selectedBook.splice(index, 1);
                } else {
                    this.selectedBook.push(id);
                }
                if (this.selectedBank.length == 0 && this.selectedBook.length == 0) {
                    this.newMatch = false;
                    this.receipt = false;
                }
            }
        },
        onSelectBooking2: function(id) {
            const selectedBook = this.conciliations.filter(function(item) {
                return item.bId == id;
            });
            const bookId = selectedBook.length > 0 ? selectedBook[0].tId : "";

            if (bookId == "") {
                if (!this.newMatch) {
                    this.selectedBank = [];
                    this.selectedBook = [];
                    this.newMatch = true;
                }
                if (this.selectedBook.find(value => value == id)) {
                    const index = this.selectedBook.indexOf(id);
                    this.selectedBook.splice(index, 1);
                } else {
                    this.selectedBook.push(id);
                }
                if (this.selectedBook.length == 0) {
                    this.selectedBook = [];
                }
                if (this.selectedBank.length == 0 && this.selectedBook.length == 0) {
                    this.newMatch = false;
                }
            } else if (!this.newMatch) {
                this.selectedBook = this.conciliations
                    .filter(function(item) {
                        return item.tId == bookId;
                    })
                    .map(unit => unit.bId);

                this.selectedBank = this.conciliations
                    .filter(function(item) {
                        return item.bId == id;
                    })
                    .map(unit => unit.tId);
            }
        },

        onSelectStatement: function(id) {
            const recon = this.conciliationBanks.find(item => item.id == id);
            if (recon != undefined) {
                if (!this.coloredMarked || this.lastReconId != recon.recId) {
                    this.selectedRecon = this.conciliations.find(item => item.recId == recon.recId);
                    let selectedBank2 = this.conciliationBanks.filter(item => item.recId == recon.recId);
                    this.selectedBank = selectedBank2.map(unit => unit.id);
                    this.selectedBook = this.conciliationBookings.filter(item => item.recId == recon.recId).map(unit => unit.id);
                    this.coloredMarked = true;
                    this.lastReconId = recon.recId;
                    this.earning = selectedBank2[0].type == 2;
                    this.newMatch = this.selectedBank.length == 1 && this.selectedBook.length == 0;
                    this.receipt = this.newMatch && !this.earning;
                } else {
                    this.selectedRecon = {};
                    this.selectedBank = [];
                    this.selectedBook = [];
                    this.coloredMarked = false;
                    this.receipt = false;
                    this.earning = false;
                    this.newMatch = false;
                }
            } else {
                this.earning = false;
                this.receipt = false;
                this.coloredMarked = false;
                this.selectedRecon = {};
                if (!this.newMatch) {
                    this.selectedBank = [];
                    this.selectedBook = [];
                    this.newMatch = true;
                }
                if (this.selectedBank.find(value => value == id)) {
                    const index = this.selectedBank.indexOf(id);
                    this.selectedBank.splice(index, 1);
                } else {
                    this.selectedBank.push(id);
                }
                if (this.selectedBank.length == 0 && this.selectedBook.length == 0) {
                    this.newMatch = false;
                    this.receipt = false;
                }
            }
        },
        onSelectStatement2: function(id) {
            const selectedBank = this.conciliations.filter(function(item) {
                return item.tId == id;
            });
            let result = this.checkSelectedBankLink([id.toString()], []);
            if (result.length > 1) {
                this.selectedBank = result;
                return;
            }
            const bookId = selectedBank.length > 0 ? selectedBank[0].bId : "";
            // Inga länkningar eller kvitto förfrågan
            if (bookId == "" || bookId == null) {
                if (bookId == null) this.receipt = true;
                if (!this.newMatch) {
                    this.selectedBank = [];
                    this.selectedBook = [];
                    this.newMatch = true;
                }
                if (this.selectedBank.find(value => value == id)) {
                    const index = this.selectedBank.indexOf(id);
                    this.selectedBank.splice(index, 1);
                } else {
                    this.selectedBank.push(id);
                }
                if (this.selectedBank.length == 0) {
                    this.selectedBank = [];
                }
                if (this.selectedBank.length == 0 && this.selectedBook.length == 0) {
                    this.newMatch = false;
                    this.receipt = false;
                }
            } else if (bookId == null) {
                this.selectedBank = selectedBank.map(unit => unit.tId);
                this.selectedBook = [];
                this.newMatch = false;
            } else if (!this.newMatch) {
                this.selectedBank = this.conciliations
                    .filter(function(item) {
                        return item.bId == bookId;
                    })
                    .map(unit => unit.tId);

                this.selectedBook = this.conciliations
                    .filter(function(item) {
                        return item.tId == id;
                    })
                    .map(unit => unit.bId);
            }
        },
        // checkSelectedBankLink: function(idList, accumulator) {
        //   idList.forEach(id => {
        //     if (!accumulator.find(ac => ac == id)) accumulator.push(id);
        //   });
        //   const selectedBank = this.conciliationBanks.filter(function(item) {
        //     return idList.find(id => id == item.tId1 || id == item.tId2);
        //   });
        //   let newIdList = [];
        //   selectedBank.forEach(item => {
        //     if (!accumulator.find(ac => ac == item.tId1)) {
        //       if (!newIdList.find(nid => nid == item.tId1)) newIdList.push(item.tId1);
        //     }
        //     if (!accumulator.find(ac => ac == item.tId2)) {
        //       if (!newIdList.find(nid => nid == item.tId2)) newIdList.push(item.tId2);
        //     }
        //   });
        //   if (newIdList.length == 0) return accumulator;
        //   return this.checkSelectedBankLink(newIdList, accumulator);
        // },

        onClientChange: function(item) {
            this.clear();
            this.selectedMonth = null;

            if (item) {
                this.selectedClient = item.value;
                this.$emit("on-client-change", item.value);
            }
            // document.title = "Bank - " + item.name.split(", ")[1];
        },
        onFinancialYearChange: async function(item) {
            this.selectedFinancialYear = item;
            this.months = await Api.getMonths(this.selectedClient, item);
            this.selectedMonth = "";
            //this.clear();
            //      this.$emit("on-financial-year-change", item);
            this.$emit("on-financial-year-change", { month: null, financialYearId: this.selectedFinancialYear });
        },
        onMonthChange: function(value) {
            this.selectedMonth = value;
            this.clear();
            this.$emit("on-financial-year-change", { month: value, financialYearId: this.selectedFinancialYear });
        },
        onAutoConciliation: function() {
            this.$emit("on-auto-conciliation");
        },
        onCopyToNew: function() {
            this.$emit("on-copy-to-new");
        },
        onRefresh: function() {
            this.$emit("on-refresh");
        },
        createColoredBankList: function() {
            var results = [];
            this.statements.forEach(statement => {
                let banks = [];
                let bookings = [];
                let description = "";
                // let sumBank = 0;
                let sumBook = 0;
                let receipt = false;
                let earning = false;
                const root = this.conciliationBanks.find(item => item.id == statement.id);
                if (root != undefined) {
                    banks = this.conciliationBanks.filter(item => item.recId == root.recId);
                    bookings = this.conciliationBookings.filter(item => item.recId == root.recId);
                    const desc = this.conciliations.find(item => item.recId == root.recId);
                    if (desc) {
                        description = desc.description;
                    }
                }
                if (banks.length == 1 && bookings.length == 0 && banks[0].type != 2) receipt = true;
                else if (banks.length == 1 && bookings.length == 0 && banks[0].type == 2) earning = true;
                else if (banks.length >= 2) {
                    sumBook = banks.reduce((a, b) => a + b.amount, 0);
                    // sumBank = statement.amount;
                }
                if (banks.length > 0) {
                    results.push({
                        id: statement.id,
                        tAmount: statement.amount,
                        cAmount: sumBook,
                        match: true,
                        count: banks[0].count > 1,
                        receipt: receipt,
                        earning: earning,
                        description: description,
                    });
                }
            });
            this.coloredBankList = results;
        },
        createColoredBankList2: function() {
            var results = [];
            this.statements.forEach(statement => {
                let sumBank = 0;
                let sumBook = 0;
                let receipt = false;
                const concBank = this.conciliationBanks.filter(item => {
                    return item.tId1 == statement.id || item.tId2 == statement.id;
                });
                const conc = this.conciliations.filter(item => {
                    return item.tId == statement.id;
                });
                if (conc.length == 1 && conc[0].bId == null) {
                    receipt = true;
                } else if (conc.length >= 2) {
                    sumBook = conc.reduce((a, b) => a + b.amount, 0);
                    sumBank = statement.amount;
                } else if (conc.length == 1) {
                    const bookId = conc[0].bId;
                    const booking = this.bookings.find(item => item.id == bookId);
                    const conc2 = this.conciliations.filter(item => {
                        return item.bId == bookId;
                    });
                    sumBank = conc2.reduce((a, b) => a + b.amount, 0);
                    sumBook = booking.amount;
                }
                if (conc.length > 0 || concBank.length > 0) {
                    results.push({
                        id: statement.id,
                        tAmount: statement.amount,
                        cAmount: sumBook,
                        match: sumBank === sumBook,
                        receipt: receipt,
                    });
                }
            });
            this.coloredBankList = results;
        },
        createColoredBookList: function() {
            var results = [];
            this.bookings.forEach(booking => {
                // let banks = [];
                let bookings = [];
                // let sumBank = 0;
                let sumBook = 0;
                let receipt = false;
                const root = this.conciliationBookings.find(item => item.id == booking.id);
                if (root != undefined) {
                    // banks = this.conciliationBanks.filter(item => item.recId == root.recId);
                    bookings = this.conciliationBookings.filter(item => item.recId == root.recId);
                }
                if (bookings.length >= 2) {
                    sumBook = bookings.reduce((a, b) => a + b.amount, 0);
                    // sumBank = statement.amount;
                }
                if (bookings.length > 0) {
                    results.push({
                        id: booking.id,
                        tAmount: booking.amount,
                        cAmount: sumBook,
                        match: true,
                        receipt: receipt,
                    });
                }
            });
            this.coloredBookList = results;
        },
        createColoredBookList2: function() {
            // var results = [];
            // this.bookings.forEach(booking => {
            //   let sumBank = 0;
            //   let sumBook = 0;
            //   const concBook = this.conciliationBookings.filter(item => {
            //     return item.bId1 == booking.id || item.bId2 == booking.id;
            //   });
            //   const conc = this.conciliations.filter(item => {
            //     return item.bId == booking.id;
            //   });
            //   if (conc.length >= 2) {
            //     sumBank = conc.reduce((a, b) => a + b.amount, 0);
            //     sumBook = booking.amount;
            //   } else if (conc.length == 1) {
            //     const bankId = conc[0].tId;
            //     const statement = this.statements.find(item => item.id == bankId);
            //     const conc2 = this.conciliations.filter(item => {
            //       return item.tId == bankId;
            //     });
            //     sumBook = conc2.reduce((a, b) => a + b.amount, 0);
            //     sumBank = statement.amount;
            //   }
            //   if (conc.length > 0 || concBook.length > 0) {
            //     results.push({
            //       id: booking.id,
            //       tAmount: booking.amount,
            //       cAmount: sumBook,
            //       match: sumBook === sumBank
            //     });
            //   }
            // });
            // this.coloredBookList = results;
        },
        groupBy: function(list, keyGetter) {
            const map = new Map();
            list.forEach(item => {
                const key = keyGetter(item);
                const collection = map.get(key);
                if (!collection) {
                    map.set(key, [item]);
                } else {
                    collection.push(item);
                }
            });
            return map;
        },
        sumBy: function(map, keyGetter) {
            var results = [];
            map.forEach((value, key) => {
                const sum = value.reduce((a, b) => a + keyGetter(b), 0);
                results.push({
                    id: key,
                    sum: sum,
                });
            });
            return results;
        },
        clear: function() {
            this.selectedBank = [];
            this.selectedBook = [];
            this.coloredBankList = [];
            this.coloredBookList = [];
            this.showChecked = true;
            this.newMatch = false;
        },
    },
    computed: {
        mappedStatements: function() {
            let results = [];
            this.statements.forEach(item => {
                let rec = {};
                rec = {
                    ...this.conciliationBanks.find(item2 => {
                        return item2.id == item.id;
                    }),
                };
                let count = rec.count;
                let newItem = {
                    id: item.id,
                    text: item.text,
                    amount: item.amount,
                    bookingDate: item.bookingDate,
                    balance: item.balance,
                    count: count,
                };
                results.push(newItem);
            });
            return results;
        },
        markedStatements: function() {
            var results = [];
            this.selectedBank.forEach(selected => {
                const statement = this.statements.filter(function(item) {
                    return item.id == selected;
                });
                results.push(statement[0]);
            });
            return results;
        },
        markedBookings: function() {
            var results = [];
            this.selectedBook.forEach(selected => {
                const booking = this.bookings.filter(function(item) {
                    return item.id == selected;
                });
                results.push(booking[0]);
            });
            return results;
        },
    },
};
</script>
<style scoped>
dl {
    display: flex !important;
    flex-wrap: wrap !important;
}
dt {
    width: 33% !important;
    font-weight: 500;
    font-size: 13px;
    color: darkslategrey;
}
dd {
    margin-left: auto !important;
    font-weight: 500;
    font-size: 13px;
    width: 66% !important;
    color: darkslategrey;
}
</style>
