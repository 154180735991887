<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">
                Avstämningsfönster
            </p>
        </header>
        <div style="height: 175px;">
            <div class="table-container">
                <table class="table is-narrow is-fullwidth">
                    <thead>
                        <tr>
                            <th width="50px" />
                            <th align="right">Antal</th>
                            <th align="right">Belopp</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Bankkonto</td>
                            <td align="right">{{ bankCount }}</td>
                            <td align="right">{{ (bankAmount / 100) | swedishNumberFormat }}</td>
                        </tr>
                        <tr>
                            <td>Bokföring</td>
                            <td align="right">{{ bookCount }}</td>
                            <td align="right">{{ (bookAmount / 100) | swedishNumberFormat }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>Differans</th>
                            <td />
                            <th style="width: 105px" align="right">{{ (diffAmount / 100) | swedishNumberFormat }}</th>
                        </tr>
                        <tr v-if="newMatch && diffAmount === 0">
                            <th>Notering</th>
                            <td colspan="2"><input class="input is-small" v-model="note" /></td>
                        </tr>
                        <tr v-if="totalCount >= 1">
                            <th>Notering</th>
                            <td colspan="2">{{ note }}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        <footer class="card-footer" style="padding: 7px;height: 55px">
            <div class="buttons" style="">
                <button v-if="newMatch && diffAmount === 0" class="button is-success" @click.prevent="saveMatch">
                    Koppla
                </button>
                <button v-if="newMatch" class="button" @click.prevent="abortMatch">
                    Avbryt
                </button>
                <button v-if="!newMatch && totalCount > 0" class="button is-danger" @click.prevent="removeMatch">
                    Ta bort matchning
                </button>
                <button v-if="isReceipts && !receipt && diffAmount != 0 && newMatch && bankCount >= 1 && bookCount == 0" class="button is-success" @click.prevent="createReceipts">
                    Skapa kvittoförfrågan
                </button>
                <button v-if="isEarnings && !earning && diffAmount != 0 && newMatch && bankCount >= 1 && bookCount == 0" class="button is-success" @click.prevent="createEarnings">
                    Skapa intäktsförfrågan
                </button>
                <button v-if="earning && newMatch && bankCount == 1 && bookCount == 0" class="button is-info" @click.prevent="removeEarning">
                    Ta bort intäktsförfrågan
                </button>
                <button v-if="isSuperAdmin && newMatch && !receipt && !earning && bankCount == 1" class="button is-danger" @click.prevent="removeTransaction">
                    Ta bort transaktion
                </button>
                <button v-if="newMatch && receipt" class="button is-info" @click.prevent="removeReceipt">
                    Ta bort kvittoförfrågan
                </button>
            </div>
        </footer>
    </div>
</template>
<script>
export default {
    props: ["statements", "bookings", "reConciliation", "newMatch", "receipt", "earning"],
    data: function() {
        return {
            note: "",
        };
    },
    methods: {
        abortMatch: function() {
            this.$emit("on-abort-match");
        },
        saveMatch: function() {
            this.$emit("on-save-match", { statements: this.statements, bookings: this.bookings, note: this.note });
        },
        removeMatch: function() {
            this.$emit("on-remove-match", this.reConciliation.recId);
        },
        createReceipts: function() {
            this.$emit("on-create-receipts", { statements: this.statements });
        },
        createEarnings: function() {
            this.$emit("on-create-earnings", { statements: this.statements });
        },
        removeReceipt: function() {
            this.$emit("on-remove-receipt", { statements: this.statements, recId: this.reConciliation.recId });
        },
        removeEarning: function() {
            this.$emit("on-remove-earning", { statements: this.statements, recId: this.reConciliation.recId });
        },
        removeTransaction: function() {
            this.$emit("on-remove-transaction", { statements: this.statements });
        },
    },
    computed: {
        isSuperAdmin: function() {
            return this.$store.state.login.userName == "bjorn.preutz@bolageriet.se";
        },
        bankAmount: function() {
            let sum = 0;
            if (this.statements) {
                sum = this.statements.reduce((a, b) => a + b.amount, 0);
            }
            return sum;
        },
        bookAmount: function() {
            let sum = 0;
            if (this.bookings) {
                sum = this.bookings.reduce((a, b) => a + b.amount, 0);
            }
            return sum;
        },
        bookCount: function() {
            let count = 0;
            if (this.bookings) {
                count = this.bookings.length;
            }
            return count;
        },
        bankCount: function() {
            let count = 0;
            if (this.statements) {
                count = this.statements.length;
            }
            return count;
        },
        isReceipts: function() {
            let count = 0;
            if (this.statements) {
                count = this.statements.filter(item => item.amount > 0);
            }
            return count.length == 0;
        },
        isEarnings: function() {
            let count = 0;
            if (this.statements) {
                count = this.statements.filter(item => item.amount < 0);
            }
            return count.length == 0;
        },
        diffAmount: function() {
            const diff = this.bankAmount - this.bookAmount;
            return diff;
        },
        totalCount: function() {
            const count = this.bankCount + this.bookCount;
            return count;
        },
    },
    watch: {
        reConciliation: function() {
            this.note = this.reConciliation.description;
        },
    },
};
</script>
