<template>
    <List :items="statementsFiltered" :scroll="true" :search="false" :headers="headers" :getrowclass="getLinkColor" @click="clickRow" style="font-size: 13px;height: 440px;">
        <template slot-scope="statement">
            <td>{{ statement.id }}</td>
            <td>{{ statement.count }}</td>
            <td>{{ statement.bookingDate }}</td>
            <td>{{ statement.text }}</td>
            <td align="right">{{ statement.amount | swedishNumberFormat }}</td>
            <td align="right">{{ statement.balance | swedishNumberFormat }}</td>
        </template>
    </List>
</template>

<script>
import List from "../../../../components/list.vue";
export default {
    components: {
        List,
    },
    props: ["statements", "selectedList", "coloredList", "showChecked", "viewOption"],
    data: function() {
        return {
            headers: [
                { name: "Id", prop: "id", width: "30px" },
                { name: "Count", prop: "count", width: "30px" },
                { name: "Datum", prop: "bookingDate", width: "90px" },
                { name: "Text", prop: "text" },
                { name: "Belopp", prop: "amount", width: "110px", align: "right" },
                { name: "Saldo", prop: "balance", width: "110px", align: "right" },
            ],
        };
    },
    mounted() {
        // eslint-disable-next-line no-console
        //console.log("statementsLocal", this.statementsLocal);
    },
    methods: {
        clickIcon: function() {},
        getLinkColor: function(transaction) {
            let diff = false;
            let ok = false;
            let receipt = false;
            let earning = false;
            let select = false;
            let count = false;
            let autoMatched = false;

            let transactionId = transaction.id;
            select = this.selectedList.some(item => item == transactionId);
            const marked = this.coloredList.filter(function(item) {
                return item.id === transactionId;
            });
            if (marked.length == 1 && marked[0].earning == false && marked[0].receipt == true) {
                if (marked[0].description == "Bank kvitto matchning") {
                    autoMatched = true;
                } else {
                    receipt = true;
                }
            } else if (marked.length == 1 && marked[0].earning == true) {
                earning = true;
            } else if (marked.length > 0) {
                if (marked[0].match) {
                    if (marked[0].count) {
                        count = true;
                    } else {
                        ok = true;
                    }
                } else {
                    diff = true;
                }
            }

            // if (transaction.conciliations.length > 0) {
            //   let sum = transaction.conciliations.reduce(function(total, currentValue) {
            //     return total + currentValue.amount;
            //   }, 0);
            //   if (sum == transaction.amount) {
            //     ok = true;
            //     diff = false;
            //   } else {
            //     ok = false;
            //     diff = true;
            //   }
            // }
            const result = {
                selected: select,
                linkcolorok: ok,
                linkcolordiff: diff,
                linkcolorRed: count,
                linkcolorreceipt: receipt,
                linkcolorearning: earning,
                linkcolorAutoMatched: autoMatched,
            };
            return result;
        },
        clickRow: function(statement) {
            this.$emit("on-select", statement.id);
        },
    },
    computed: {
        clientsMaped: function() {
            return this.statements.map(item => ({
                id: item.id,
                count: item.count,
                amount: item.amount / 100,
                balance: item.balance / 100,
                text: item.text,
                bookingDate: item.bookingDate,
            }));
        },
        statementsFiltered: function() {
            return this.clientsMaped.filter(item => {
                return (
                    (this.showChecked || !this.getLinkColor(item).linkcolorok) &&
                    ((this.viewOption == "in" && item.amount >= 0) || (this.viewOption == "out" && item.amount < 0) || this.viewOption == "all")
                );
            });
        },
    },
};
</script>
<style>
.linkcolorAutoMatched {
    background-color: #ebe981 !important;
}
.linkcolorok {
    background-color: #ccddcc !important;
}
.linkcolordiff {
    background-color: #f7d3ab !important;
}
.linkcolorreceipt {
    background-color: #abccf7 !important;
}
.linkcolorearning {
    background-color: #e7cce9 !important;
}
.linkcolorRed {
    background-color: #f5b8b8 !important;
}
.selected {
    border-style: solid !important;
    border-width: 2px !important;
    border-color: #000000 !important;
}
</style>
<style scoped>
th {
    background: white;
    position: sticky;
    top: 0;
}
</style>
