<template>
    <!-- <section class="section">
    <div class="container"> -->
    <conciliation
        :state="state"
        :statements="statements"
        :bookings="bookings"
        :conciliations="conciliations"
        :conciliation-banks="conciliationBanks"
        :conciliation-bookings="conciliationBookings"
        :financial-years="financialYears"
        @on-client-change="onClientChange"
        @on-financial-year-change="onFinancialYearChange"
        @on-refresh="onRefresh"
        @on-auto-conciliation="onAutoConciliation"
        @on-copy-to-new="onCopyToNew"
        @on-save-match="onSaveMatch"
        @on-remove-match="onRemoveMatch"
        @on-create-receipts="onCreateReceipts"
        @on-create-earnings="onCreateEarnings"
        @on-remove-receipt="onRemoveReceipt"
        @on-remove-earning="onRemoveEarning"
        @on-remove-transaction="onRemoveTransaction"
    />
    <!-- </div>
  </section> -->
</template>

<script>
import Conciliation from "./ui/conciliation.container.vue";
import Api from "./conciliation.api.js";
export default {
    components: {
        Conciliation,
    },
    data: function() {
        return {
            state: "ready",
            clientId: undefined,
            financialYearId: undefined,
            month: "",
            statements: [],
            financialYears: [],
            bookings: [],
            conciliations: [],
            conciliationBanks: [],
            conciliationBookings: [],
        };
    },
    async created() {
        document.title = "Bank";
    },
    methods: {
        onClientChange: async function(clientId) {
            this.clientId = clientId;
            this.financialYears = await Api.getFinancialYears(this.clientId);
            this.financialYearId = this.financialYears.selected;
            this.statements = [];
            this.bookings = [];
            this.conciliations = [];
            this.month = "";
            await this.onRefresh();
        },
        onFinancialYearChange: async function(data) {
            this.financialYearId = data.financialYearId;
            this.month = data.month;
            await this.onRefresh();
        },
        onRefresh: async function() {
            this.statements = [];
            this.bookings = [];
            this.conciliations = [];
            const statements = Api.getBankStatementsAsync(this.clientId, this.financialYearId, this.month);
            const bookings = Api.getBookingsAsync(this.clientId, this.financialYearId, this.month);
            const result = Api.getConciliationsAsync(this.clientId, this.financialYearId, this.month);
            // const conciliationBanks = result.conciliationBankDtos;
            // const conciliationBookings = result.conciliationBookingDtos;
            this.statements = await statements;
            this.bookings = await bookings;
            const resultConciliations = await result;
            this.conciliations = resultConciliations.reconciliationDtos;
            this.conciliationBanks = resultConciliations.reconciliationBankDtos;
            this.conciliationBookings = resultConciliations.reconciliationBookingDtos;
        },
        onAutoConciliation: async function() {
            this.state = "loading";
            const resultConciliations = await Api.doAutoConciliationAsync(this.clientId, this.financialYearId, this.month);
            this.$notify.success({ title: "Utfört" });
            this.conciliations = resultConciliations.reconciliationDtos;
            this.conciliationBanks = resultConciliations.reconciliationBankDtos;
            this.conciliationBookings = resultConciliations.reconciliationBookingDtos;
            this.state = "ready";
        },
        onCopyToNew: async function() {
            const resultConciliations = await Api.doCopyToNewAsync(this.clientId, this.financialYearId);
            this.$notify.success({ title: "Utfört" });
            this.conciliations = resultConciliations.reconciliationDtos;
            this.conciliationBanks = resultConciliations.reconciliationBankDtos;
            this.conciliationBookings = resultConciliations.reconciliationBookingDtos;
        },
        onSaveMatch: async function(data) {
            const resultConciliations = await Api.saveMatchAsync(this.clientId, this.financialYearId, data);
            this.$notify.success({ title: "Utfört" });
            this.conciliations = resultConciliations.reconciliationDtos;
            this.conciliationBanks = resultConciliations.reconciliationBankDtos;
            this.conciliationBookings = resultConciliations.reconciliationBookingDtos;
        },
        onRemoveMatch: async function(recId) {
            const resultConciliations = await Api.removeMatchAsync(this.clientId, this.financialYearId, recId);
            this.$notify.success({ title: "Utfört" });
            this.conciliations = resultConciliations.reconciliationDtos;
            this.conciliationBanks = resultConciliations.reconciliationBankDtos;
            this.conciliationBookings = resultConciliations.reconciliationBookingDtos;
        },
        onCreateReceipts: async function(data) {
            const resultConciliations = await Api.createReceiptsAsync(this.clientId, this.financialYearId, data);
            this.$notify.success({ title: "Utfört" });
            this.conciliations = resultConciliations.reconciliationDtos;
            this.conciliationBanks = resultConciliations.reconciliationBankDtos;
            this.conciliationBookings = resultConciliations.reconciliationBookingDtos;
        },
        onCreateEarnings: async function(data) {
            const resultConciliations = await Api.createEarningsAsync(this.clientId, this.financialYearId, data);
            this.$notify.success({ title: "Utfört intäkt" });
            this.conciliations = resultConciliations.reconciliationDtos;
            this.conciliationBanks = resultConciliations.reconciliationBankDtos;
            this.conciliationBookings = resultConciliations.reconciliationBookingDtos;
        },
        onRemoveReceipt: async function(data) {
            const resultConciliations = await Api.removeReceiptAsync(this.clientId, this.financialYearId, data);
            this.$notify.success({ title: "Utfört" });
            this.conciliations = resultConciliations.reconciliationDtos;
            this.conciliationBanks = resultConciliations.reconciliationBankDtos;
            this.conciliationBookings = resultConciliations.reconciliationBookingDtos;
        },
        onRemoveEarning: async function(data) {
            const resultConciliations = await Api.removeEarningAsync(this.clientId, this.financialYearId, data);
            this.$notify.success({ title: "Utfört" });
            this.conciliations = resultConciliations.reconciliationDtos;
            this.conciliationBanks = resultConciliations.reconciliationBankDtos;
            this.conciliationBookings = resultConciliations.reconciliationBookingDtos;
        },
        onRemoveTransaction: async function(data) {
            const resultConciliations = await Api.removeTransactionAsync(this.clientId, this.financialYearId, data);
            this.$notify.success({ title: "Utfört" });
            this.conciliations = resultConciliations.reconciliationDtos;
            this.conciliationBanks = resultConciliations.reconciliationBankDtos;
            this.conciliationBookings = resultConciliations.reconciliationBookingDtos;
        },
    },
};
</script>
